import config from '../config';
import loadFirebase from './firebase';

export default async () => {
    const firebase = await loadFirebase();
    await import('firebase/firestore');

    try {
        firebase.initializeApp(config.firebase);
    } catch (err) {
        // we skip the "already exists" message which is
        // not an actual error when we're hot-reloading
        if (!/already exists/.test(err.message)) {
            console.error('Firebase initialization error', err.stack);
        }
    }
    const db = firebase.firestore();
    if (process.env.GATSBY_EMULATOR === 'true') {
        db.useEmulator('localhost', 8080);
    }
    return db;
};
